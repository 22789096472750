import React, { useState, useRef, useEffect } from 'react';

import { IoMdArrowDropdownCircle } from "react-icons/io";
import "./App.css"
import "./override.css"
const App = () => {
  const [step, setStep] = useState('start');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [combinedImage, setCombinedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [filename, setFilename] = useState(null);
  const fileInputRef = useRef(null);
  const qrCodeRef = useRef(null);
  const dropdownClassName = isOpen ? "bg-transparent" : "bg-white";
  const backgroundOptions = [
    'ROCK LEGENDS', 'RAP ROYALTY', 'THROWBACK GROOVES', 'UNDERGROUND ANTHEMS', 
    'Y2K VIBES', 'EDM BANGERS', 'DREAM POP', 'URBAN BEATS'
  ];
  const getBackgroundImage = () => {
    return step === 'start' ? '/Artboard 1.png' : '/Artboard 1.jpg';
  };
  useEffect(() => {
    if (step === 'qr' && qrCodeUrl && qrCodeRef.current) {
    
      const loadQRCodeScript = () => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = '/assets/qrcode.min.js';
          script.onload = () => resolve(window.QRCode);
          script.onerror = () => reject(new Error('Failed to load QRCode script'));
          document.body.appendChild(script);
        });
      };
  
      const generateQRCode = async () => {
        try {
          const QRCode = await loadQRCodeScript();
          console.log(filename);
        //  const url = "https://customphotoexperience.com/server/fetch-image.html?filename=${filename}" 
          const url = `${window.location.origin}/server/fetch-image.html?filename=${filename}`;
          
          if (qrCodeRef.current) {
            new QRCode(qrCodeRef.current, {
              text: url,
              width: 230,
              height: 230,
            });

            // Access the second child of qrCodeRef.current and add styles
            const qrCodeElement = qrCodeRef.current.children[1];
            if (qrCodeElement) {
              qrCodeElement.style.padding = '10px';
              qrCodeElement.style.border = '10px solid black';
            }
          }
        } catch (error) {
          console.error('Error loading QRCode script:', error);
        }
      }
      generateQRCode();
    }
  }, [step, qrCodeUrl]);
  
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleBackgroundSelection = (background) => {
    setSelectedBackground(background);
    setIsOpen(false);
    fileInputRef.current?.click();
  };

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    const imagesToPreload = [
      '/assets/Artboard 1 copy.png',
      '/assets/next-removebg-preview.png',
      '/assets/Vector Smart Object(3).png',
      '/assets/SCAN QR CODE FOR A DIGITAL COPY!(1).png'
      // Add more image URLs as needed
    ];

    preloadImages(imagesToPreload);
  }, []);

  const preloadFonts = (fontUrls) => {
    fontUrls.forEach((url) => {
      const link = document.createElement('link');
      link.href = url;
      link.rel = 'preload';
      link.as = 'font';
      link.type = 'font/ttf';
      link.crossOrigin = 'anonymous'; // Ensure CORS headers are set correctly on the font file
      document.head.appendChild(link);
    });
  };
  
  useEffect(() => {
    const fontsToPreload = [
      '/assets/TT Bluescreens Trial ExtraBold.ttf',
      // Add more font URLs as needed
    ];
  
    preloadFonts(fontsToPreload);
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (!file) {
      alert('No photo selected.');
      return;
    }

    setStep('preview');
    setIsLoading(true);
    setCapturedImage(URL.createObjectURL(file));

    try {
      console.log('Starting image processing');
      await processImage(file);
      console.log('Image processing complete');
    } catch (error) {
      console.error('Error processing image:', error);
      alert('An error occurred while processing your image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const processImage = async (file) => {
    
    console.log('Removing background');
    const removedBgBlob = await removeBackground(file);
    const removedBgUrl = URL.createObjectURL(removedBgBlob);
    console.log('Combining images');
    // var actionbuttons = document.querySelectorAll('.retake-download-width');
    // actionbuttons[0].disabled = true;
    // actionbuttons[1].disabled = true;
    await combineImages(removedBgUrl, `${selectedBackground}_BACKGROUND.png`, `${selectedBackground}_OVERLAY.png`);
    console.log('Image processing successful');
  };

  const removeBackground = async (imageFile) => {
    const url = 'https://sdk.photoroom.com/v1/segment';
    const apiKey = process.env.REACT_APP_API_KEY;

    const formData = new FormData();
    formData.append('image_file', imageFile);

    const response = await fetch(url, {
      method: 'POST',
      headers: { 'X-Api-Key': apiKey ?? '' },
      body: formData,
    });
    console.log(response)
    if (!response.ok) {
      console.error(await response.json());
      throw new Error('Network response was not ok');
    }

    return await response.blob();
  };

  const combineImages = (mainImage, backgroundImage, overlayImage) => {
    return new Promise((resolve, reject) => {
      
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const background = new Image();
      const img = new Image();
      const overlay = new Image();
  
      const handleImageError = (imageName) => (error) => {
        console.error(`Failed to load image: ${imageName}`, error);
        reject(`Failed to load image: ${imageName}`);
      };
  
      const handleBlobOrPath = (imgElement, src) => {
        if (src.startsWith('blob:') || src.startsWith('data:')) {
          imgElement.src = src;
        } else {
          imgElement.src = `/assets/${src}`;  // Ensure this is the correct path to your assets
        }
      };
  
      background.onload = () => {
        console.log('Background image loaded successfully');
        canvas.width = background.width;
        canvas.height = background.height;
        ctx?.drawImage(background, 0, 0);
  
        img.onload = () => {
          console.log('Main image loaded successfully');
          const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
          const newWidth = img.width * scale;
          const newHeight = img.height * scale;
          const x = (canvas.width - newWidth) / 1.5;
          // const y = (canvas.height - newHeight) / 1.5;
          const y = canvas.height - newHeight;
  
          ctx?.drawImage(img, x, y, newWidth, newHeight);
  
          overlay.onload = () => {
            console.log('Overlay image loaded successfully');
            ctx?.drawImage(overlay, 0, 0, canvas.width, canvas.height);
            const combined = canvas.toDataURL('image/jpeg', 0.3);

            //display buttons
            var actionbuttons = document.querySelectorAll('.retake-download-width');
            actionbuttons[0].style.display = "block";
            actionbuttons[1].style.display = "block";
            setCombinedImage(combined);
            resolve();
          };
          overlay.onerror = handleImageError(overlayImage);
          handleBlobOrPath(overlay, overlayImage);
        };
        img.onerror = handleImageError(mainImage);
        handleBlobOrPath(img, mainImage);
      };
      background.onerror = handleImageError(backgroundImage);
      handleBlobOrPath(background, backgroundImage);
    });
  };
  
  

  const handleUploadForQR = async () => {
    if (!combinedImage) {
      alert('No combined image to upload.');
      return;
    }
    // var actionbuttons = document.querySelectorAll('.retake-download-width');
    // actionbuttons[0].disabled = true;
    // actionbuttons[1].disabled = true;
    var actionbuttons = document.querySelectorAll('.retake-download-width');
    actionbuttons[0].style.display = "none";
    actionbuttons[1].style.display = "none";

    setIsLoading(true);

    try {
      const response = await fetch(combinedImage);
      const blob = await response.blob();

      const formData = new FormData();
      const filename = `${Date.now()}.jpg`
      setFilename(filename);
      
      const chicagoTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/Chicago'
      });
      const [date, time] = chicagoTime.split(', ');

      const data = new FormData();
      data.append('Date',date);
      data.append('Time',time);
      data.append('Photolink',"https://customphotoexperience.com/server/uploads/"+filename);

      const action = "https://script.google.com/macros/s/AKfycbwrykdKa-HgZzqklo4_htMzRX9C8EWkqR1Y1DT_xCMrSkpNJi-4ZsBgNEGvzZKLgXxdtw/exec";
      fetch(action, {
          method: 'POST',
          body: data,
      })
      .then(response => response.text())
      .then(text => {
          console.log(text);
      })

      var formData1 = new FormData();
      formData1.append('status', "shared");

      const action1 = "https://script.google.com/macros/s/AKfycby89-kzdYlMYCpIwu0UZvMKXF1gGRZIKr6htuchaq7mtezM77vrqnf521TbnF_XrTSwwA/exec";
      fetch(action1, {
          method: 'POST',
          body: formData1,
      })
      .then(response => response.text())
      .then(text => {
          console.log(text);
      })



      // Append the file to formData with the datetime-based filename
      formData.append('file', blob, filename);
      formData.append('filename', filename);

      {/*here we will */}

      const uploadResponse = await fetch('https://customphotoexperience.com/server/upload.php', {
        method: 'POST',
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error(`HTTP error! status: ${uploadResponse.status}`);
      }

      const result = await uploadResponse.json();
      if (result.message === 'Success' && result.file_url) {
        setQrCodeUrl(result.file_url); 
        setStep('qr');
      } else {
        throw new Error(result.message || 'Unknown error occurred during upload');
      }
    } catch (error) {
      console.error('Error uploading combined image:', error);
      
      let errorMessage = 'An error occurred while uploading the image. ';
      if (error instanceof TypeError) {
        errorMessage += 'Please check your internet connection and try again.';
      } else if (error.message.includes('HTTP error')) {
        errorMessage += 'The server encountered an issue. Please try again later.';
      } else {
        errorMessage += error.message;
      }
      
      alert(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRetake = () => {
    var actionbuttons = document.querySelectorAll('.retake-download-width');
    actionbuttons[0].style.display = "none";
    actionbuttons[1].style.display = "none";
    setCapturedImage(null);
    setCombinedImage(null);
    setStep('dropdown');
  };
  const handleDownload = () => {
    if (!combinedImage) {
      alert('No combined image available to download.');
      return;
    }
  
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = combinedImage;  // Use the combinedImage data URL
    link.download = 'bestie-mode-playlist-cover.png';  // Set a more descriptive filename
  
    // Append to body, trigger click, then remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const getBackgroundSize = () => {
    if (window.innerWidth <= 713) {
      return "100% 100%"; // For smaller screens
    } else {
      return "contain";   // For larger screens
    }
  };
  const getBackgroundSize2 = () => {
    if (window.innerWidth <= 713) {
      return "100% 100%"; // For smaller screens
    } else {
      return "contain";   // For larger screens
    }
  };
  const [backgroundSize, setBackgroundSize] = useState(getBackgroundSize());
  const [backgroundSize2, setBackgroundSize2] = useState(getBackgroundSize2());

  // Update background size on window resize
  useEffect(() => {
    const handleResize = () => {
      setBackgroundSize(getBackgroundSize());
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div 
    className="relative w-full min-h-screen bg-cover bg-center bg-no-repeat bg-fixed "
    style={{
      backgroundImage: `url(${getBackgroundImage()})`,
      backgroundSize: backgroundSize,
    }}   
  > <div className="absolute inset-0 overflow-auto">
 {step === 'start' && (
          <div className="flex flex-col items-center justify-center h-full bg-center bg-no-repeat"  style={{
            backgroundImage: `url('/assets/Artboard 1.png')`,
            backgroundSize: backgroundSize,
          }}   >
            <img src="/assets/powered by spotify.png" alt="powered by spotify" className='w-44 md:w-50 custom:w-60 custom:h-70 custom:mt-[-350px] lg:mt-[-300px] mt-[-200px]'/>
            <button
              className="lg:mt-5 lg:mb-5 px-8 py-4 mt-5 text-3xl lg:text-xl font-bold text-white bg-black rounded-2xl custom-[-450px] ps-10 pe-10 custom:mt-14"
              onClick={() => setStep('dropdown')}
            >START      NOW
            </button>
          </div>
        )}
 </div>
     
  
 {step === 'dropdown' && (
  <div
    className="relative flex flex-col justify-center min-h-screen bg-cover bg-center bg-no-repeat bg-fixed"
    style={{
      backgroundImage: `url('/assets/Artboard 1 copy.png')`,
      backgroundSize: backgroundSize2,
    }}
  >
    <div className="relative z-10 flex flex-col items-center mt-[-100px] leading-tight">
          
      <button
        onClick={toggleDropdown}
        className={` bg-black text-white py-2 px-4  rounded text-2xl appearance-none  w-[300px] md:mt-[300px] custom-text  custom:w-[430px] md:w-[500px] flex justify-between items-center h-14 mb-7 ${isOpen ? 'mt-[200px]' : 'mt-[-170px] md:mt-[300px]'}`}>
        SELECT A BACKGROUND
        <span className="text-sm w-4 rounded-full"><img src="/assets/next-removebg-preview.png" alt="idk"/></span>
      </button>
      
      {isOpen && (
        <div className="dropdown-container w-[300px] custom:w-[430px] md:w-[500px] custom-text rounded-md shadow-lg bg-black ring-1 ring-gray-300 ring-opacity-5 mt">
          <div className="py-1 divide-y divide-gray-200 ">
            {backgroundOptions.map((option) => (
              <button
                key={option}
                onClick={() => handleBackgroundSelection(option)}
                className="block w-full px-4 py-2 h-14 text-2xl text-white hover:bg-green-500  text-left"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleFileChange}
        className="hidden"
        ref={fileInputRef}
      />
    </div>
  </div>
)}

  {step === 'preview' && (
  <div className="relative flex flex-col items-center justify-center min-h-screen bg-transparent bg-fixed bg-no-repeat bg-center "
  style={{
    backgroundImage: `url('/assets/Artboard 1 copy.png')`,
    backgroundSize: backgroundSize2,
  }}>

    <div className="flex flex-col items-center justify-center min-h-screen bg-transparent z-10">
      
  
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-[200px]">
          <div className="spinner w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      ) : (
        combinedImage && (
          <img src={combinedImage} alt="Combined" className="w-[450px] h-[450px] mt-48" />
        )
      )}
      <div className="flex justify-between w-full mt-4 px-4 ">
        <button
          onClick={handleRetake}
          className="bg-black text-white text-3xl font-bold py-2 px-4 rounded mr-10 retake-download-width"
          
        >
          RETAKE
        </button>
        <button
          onClick={handleUploadForQR}
          className="bg-black  text-white text-3xl font-bold py-2 px-4 rounded retake-download-width"
          
        >
          DOWNLOAD
        </button>
      </div>
    </div>
  </div>
)}
{step === 'qr' && (
  <div
    className="relative flex flex-col items-center justify-center min-h-screen bg-transparent  bg-fixed bg-no-repeat bg-center "
    style={{
      backgroundImage: `url('/assets/Artboard 1.png')`,
      backgroundSize: backgroundSize,
    }}
  >
    <div className="flex flex-col items-center justify-center min-h-screen bg-transparent z-10">
 
    <img src="/assets/Vector Smart Object(3).png" alt="powered by spotify" className='w-44 md:w-50 custom:w-[350px] custom:h-[150px]  custom:mt-[-300px] mt-[-300px]'/>
<img src="/assets/SCAN QR CODE FOR A DIGITAL COPY!(1).png" alt="SCAN QR CODE FOR A COPY" className="w-[350px] mt-4"></img>
     
<div ref={qrCodeRef} className="flex items-center justify-center custom:mt-[-50px] w-96 h-96">
    {/* QR code will be rendered here */}
  </div>
      <div className="flex justify-around w-full custom:mt-[-70px] px-4">
      <button
  onClick={() => setStep('start')} // Change step to 'home' to navigate to the home step
  className="bg-black custome-text text-4xl text-white py-2 px-4 rounded-xl homebutton"
>
  HOME
</button>

      </div>
    </div>
  </div>
)}

  


    </div>
  );
  
};

export default App;
